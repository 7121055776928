import { Check } from '@mui/icons-material/';
import { Alert, AlertTitle, Box, Button, Card, CardContent, Skeleton, Stack } from '@mui/material/';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import ComponentChooser from '../../components/ConfigurationComponents/ComponentChooser';
import ItemArray from '../../components/ConfigurationComponents/List';
import Page, { Header } from '../../components/Page';
import { FormContext, GetFormData, GetPathObject } from '../../contexts/FormContext';
import { useUserContext } from '../../contexts/userContext';
import { OnChangeValueType, SchemaComponent, SchemaObject } from '../../types/schema';
import { decodeGenericErrorMessage } from '../../utils/error';
import { useGetConfiguration, useGetServiceInfo, useUpdateConfigurationMutation } from './api';
import { ServiceInfo, Site } from '/src/types/site';

const ServiceConfiguration = () => {
  const [configUrl, setConfigUrl] = useState<string | null>(null);
  const { enqueueSnackbar: showSnack } = useSnackbar();
  const { currentSite } = useUserContext();
  const params = useParams();
  const { serviceId, sub } = params;
  const otherRouteParams = params['*'];

  const { data: tmpData, error, isLoading, isRefetching } = useGetConfiguration(configUrl || '');
  const [data, setData] = useState<SchemaObject>({});
  const [formIsDirty, setFormIsDirty] = useState(false);
  const updateMutation = useUpdateConfigurationMutation();
  const serviceInfo = useGetServiceInfo<ServiceInfo>(serviceId || '');
  const queryClient = useQueryClient();

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormIsDirty(true);
    const form = e.target as HTMLFormElement;
    if (!form) {
      showSnack('Something went wrong with the form, please reload page and try again.', {
        variant: 'error',
      });
      setFormIsDirty(false);
    } else if (!form?.reportValidity()) {
      showSnack('Form are missing required values, check form for errors.', { variant: 'warning' });
      setFormIsDirty(false);
    } else {
      const haveDirty = form.querySelectorAll('.dirty');
      if (haveDirty.length > 0) {
        showSnack(
          'Form is marked as dirty, make sure you save sub fields before updating the data.',
          {
            variant: 'warning',
          },
        );
        setFormIsDirty(false);
      } else {
        const postObj = GetFormData(data);
        if (postObj.siteId) {
          // eslint-disable-next-line
          delete postObj.siteId;
        }
        try {
          await updateMutation.mutateAsync({ data: postObj, url: configUrl || '' });
          await queryClient.invalidateQueries(['serviceConfiguration', configUrl]);
          showSnack('Successfully updated configuration for site', { variant: 'success' });
          setFormIsDirty(false);
        } catch (err) {
          const subMessage = decodeGenericErrorMessage(err);
          showSnack(`Could not update configuration for site, try again later. ${subMessage}`, {
            variant: 'warning',
          });
          setFormIsDirty(false);
          /* eslint-disable no-console */
          console.log(err);
        }
      }
    }
  };

  const handleChange = (id: string, newData: OnChangeValueType, wholeObject = false) => {
    if (wholeObject) {
      const objTmpData = { ...data };
      objTmpData[id] = newData;
      setData(objTmpData);
    } else {
      const po = GetPathObject(id, data);
      if (po) {
        (po as SchemaComponent).value = newData as SchemaComponent;
        setData(data);
      } else {
        console.log('Could not find path in data to update value');
      }
    }
    return data;
  };

  useEffect(() => {
    if (serviceInfo.isSuccess) {
      const urlParts = [sub, otherRouteParams, (currentSite as Site).id].filter((x) => x).join('/');
      const url = `${serviceInfo.data.basePath}${serviceInfo.data.configurationPath}${
        urlParts ? `/${urlParts}` : ''
      }`;
      setConfigUrl(url);

      document.title = `${serviceInfo.data.name} | Simply by Coor`;
    }
  }, [serviceInfo]);

  useEffect(() => {
    if (!isLoading && error) {
      setData({});
    } else if ((!isLoading || !isRefetching) && tmpData && Object.keys(tmpData).length > 0) {
      setData(tmpData as unknown as SchemaObject);
    }
  }, [tmpData, isLoading, isRefetching, error]);

  return (
    <Page>
      <Header>
        {serviceInfo.isSuccess ? serviceInfo.data.name : '...'}
        {currentSite && ` - ${(currentSite as Site).name}`}
      </Header>
      <Card variant="outlined">
        <CardContent>
          <FormContext.Provider value={{ handleChange, setFormIsDirty }}>
            {isLoading && (
              <>
                <Skeleton animation="wave" height={48} />
                <Skeleton animation="wave" height={48} />
                <Skeleton animation="wave" height={48} />
                <Skeleton animation="wave" height={48} />
                <Skeleton animation="wave" height={48} />
              </>
            )}
            {!isLoading && data && (
              <Box onSubmit={submit} component="form">
                {Object.keys(data).map((obj) => {
                  if (Array.isArray(data[obj])) {
                    return <ItemArray id={obj} comp={data} key={obj} />;
                  }
                  const dd = data[obj] as SchemaComponent;
                  return (
                    <ComponentChooser
                      key={obj}
                      id={obj}
                      comp={dd}
                      required={dd?.optional === false ? true : false}
                    />
                  );
                })}
                {!(data.hideButton as SchemaComponent)?.value && (
                  <Box sx={{ p: 1 }}>
                    <Stack direction="row" spacing={4} justifyContent="end">
                      <Button
                        variant="contained"
                        color="success"
                        type="submit"
                        disabled={formIsDirty}
                        endIcon={<Check />}>
                        {((data?.buttonText as SchemaComponent)?.value as string) || 'Save'}
                      </Button>
                    </Stack>
                  </Box>
                )}
              </Box>
            )}
            {(error as boolean) && !isLoading && (
              <Stack>
                <Alert severity="warning">
                  <AlertTitle>Error</AlertTitle>
                  Could not fetch configuration for the selected service, try again later.
                </Alert>
              </Stack>
            )}
          </FormContext.Provider>
        </CardContent>
      </Card>
    </Page>
  );
};

export default ServiceConfiguration;
